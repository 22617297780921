import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Head from "../Base/Head";

export default function Events() {
  const [showGif, setShowGif] = useState(false);
  const [gifPosition, setGifPosition] = useState("");
  const [gifRandomCache, setGifRandomCache] = useState(12345);

  const head = {
    title: "EMU",
    description: "",
    url: "https://basedemu.com",
    image: "https://i.imgur.com/YrWeCCz.gif",
  };

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    function triggerGif() {
      // generate random position - must be different than previous
      const positions = ["top", "bottom", "left", "right"];
      let newPosition = positions[getRandomInt(0, 3)];
      while (newPosition === gifPosition) {
        newPosition = positions[getRandomInt(0, 3)];
      }
      // show gif
      setGifPosition(newPosition);
      setGifRandomCache(getRandomInt(0, 1000000));
      setShowGif(true);
      // console.log(`setShowGif: true, position: ${newPosition}`);
      // hide gif after 5 seconds
      setTimeout(() => {
        setShowGif(false);
        // console.log(`setShowGif: false`);
        // wait for x time then show gif again
        setTimeout(() => {
          triggerGif();
        }, getRandomInt(3000, 9000));
      }, 5000);
    }
    triggerGif();
  }, []);

  return (
    <>
      <Head head={head} />
      <div className="emu-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/images/emu-shadow.png"}
          className="img-fluid emu-main-img"
        ></img>
        {/* <div className="header">EMU</div> */}
        <div className="emu-text-anim">
          <img
            src={process.env.PUBLIC_URL + "/images/emu-text.gif"}
            className="img-fluid emu-text-img"
            alt="EMU"
          />
        </div>
        <div className="btns-container">
          <a href="#" className="btnxxx">
            <span>Buy $EMU</span>
          </a>
          <a href="#" className="btnxxx">
            <span>Dexlab</span>
          </a>
          <a href="#" className="btnxxx">
            <span>Telegram</span>
          </a>
          <a href="#" className="btnxxx">
            <span>X / TWITTER</span>
          </a>
        </div>
        <div className="token-addr">
          <b>Token Address:</b>
          <span className="mob-only">
            <br></br>
          </span>
          <span className="addr">
            49CzESp6gicYQvWoJRrdTbVi1PAwvuzni4MbRxGekBfX
          </span>
        </div>
        {showGif && (
          <img
            src={`${process.env.PUBLIC_URL}/images/head.gif?v=${gifRandomCache}`}
            alt="gif"
            className={`emu-head-anim ${gifPosition}`}
          />
        )}
      </div>
    </>
  );
}
